/* ------------------------------------ *\
    CART PAGE
\* ------------------------------------ */

$cart_image_width: 70px;

.cart__img {
    width: $cart_image_width;
}

.cart-total-amount {
    @include border($where: bottom, $color: $grey--medium);
    @include border($where: top, $color: $grey--medium);

    &__saved-amount {
        color: $green;
        font-weight: $font-weight--bold;
        padding-top: $space * 3;

        @include border($where: top, $color: $grey--medium);

        &--icon {
            display: inline-block;
            background-color: $green;
            color: white;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            padding: 4px;
            font-size: 10px;
            margin-right: $space;
        }

        &--text {
            display: flex;
            font-size: 14px;
            line-height: 18px;
        }
    }
}

.cart-sales-price-p {
    min-height: $line-height--small;

    @include media(lg) {
        min-height: 0;
    }
}

// This styling belongs to Goodie Bundle (specifically cart related goodie-box.twig)
.cart-goodie {
    background: white;

    &__container {
        @include media(lg) {
            border-bottom: none;
        }
    }

    &__content {
        flex-direction: column;

        @include media(lg) {
            flex-direction: row;
        }

        &--right {
            display: flex;
            flex-direction: column;

            @include media(xl) {
                flex-direction: row;
            }
        }

        &-header {
            @include border($where: bottom, $color: $grey--medium);
        }

        @include media(lg) {
            border: none;
        }
    }

    &__title {
        padding: $space * 3;
    }

    &__details {
        display: flex;
        flex-direction: row;
    }

    &__product-title {
        margin-right: $space * 2;
        max-height: 36px;
        overflow: hidden;
    }

    &__badge--gratis {
        background: $green;
        border-radius: $border-radius;
        color: white;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
        padding: calc($space / 2) $space;
    }

    &__claim--top,
    &__claim--bottom {
        background-color: transparent;
        color: $blue;
        font-weight: $font-weight--bold;
        height: initial;
    }

    &__claim--top {
        display: none;

        @include media(lg) {
            display: block;
        }
    }
    &__claim--bottom {
        display: flex;
        justify-content: center;
        padding-bottom: $space * 3;
        width: 100%;

        @include media(lg) {
            display: none;
        }
    }

    &__selects {
        select {
            margin-bottom: $space * 2;
        }
    }

    &__gift-icon {
        color: $blue;
        font-size: 45px;
        padding-top: $space;
    }

    &__add-hint {
        max-width: 400px;
    }

    &__add--with-config {
        margin-top: 0;

        @include media(xl) {
            margin-top: ($space * 4) - 1;
        }
    }

    &__price--config {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include media(lg) {
            flex-direction: column;
        }

        @include media(xl) {
            align-items: flex-start;
        }
    }
}

;@import "sass-embedded-legacy-load-done:589";