//TODO: Deprecated, remove when all usages are repalced
//Refactored Version: styles/v2/components/_quantity-widget.molecule.scss

/* ----------------------------------------------- *\
    QUANTITY WIDGET (when the button is present)
\* ----------------------------------------------- */

$quantity-widget-height: $space * 8;

.quantity-widget {
    width: 100%;

    &__button--buy {
        padding: 7px 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: percentage(calc(1 / 4));
        height: $quantity-widget-height;

        [class^="icon--"] {
            margin: -2px;
        }

        @include media(lg) {
            max-width: 55px;

            [class^="icon--"] {
                font-size: 20px;
            }
        }
    }

    .quantity-field {
        width: percentage(calc(3 / 4));
        max-height: 40px;
        border-right: 0;

        &__button--up {
            border-radius: 0;
        }
    }
}

.quantity-widget {
    &__button--buy-v2 {
        width: percentage(calc(1 / 2));
        height: 48px;

        @include media(lg) {
            max-width: 68px;
        }
    }
}


/* ------------------------------------ *\
    QUANTITY FIELD (+/-)
\* ------------------------------------ */

.quantity-field {
    align-items: center;
    display: flex;
    position: relative;
    width: $space * 25;

    &__input,
    &__button {
        @include border;
        background-color: #fff;
        color: $base-text-color;
        height: $quantity-widget-height;
        text-align: center;
    }

    &__input {
        border-width: 1px 0;
        border-radius: 0;
        width: calc(100% - 80px);
        font-weight: $font-weight--bold;

        //remove the native arrows
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &.touched {
            &.valid {
                background-image: none;
            }
        }

        @include media(lg) {
            width: calc(100% - 70px);
        }
    }

    &__button {
        border-radius: 0;
        width: 40px;
        padding: 11px 0;
        background-color: #fff;
        color: $theme-color;

        &:hover {
            color: $theme-color;
            text-decoration: none;
        }

        &[disabled] {
            cursor: not-allowed;
            color: $grey;
        }

        &__button--down {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        &__button--up {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }

        @include media(lg) {
            width: 35px;
            padding: 8px 0;
        }
    }

    @include media(md) {
        width: 140px;
    }

    @include media(lg) {
        width: 120px;
    }
}

;@import "sass-embedded-legacy-load-done:913";