.testimonials {
    img {
        border-radius: 50%;
    }

    &__item {
        align-items: center;
        display: flex;
        margin-bottom: $space * 3;
        min-width: 100%;

        img {
            min-width: 70px;
            max-width: 70px;
        }
    }

    &--pdp {
        display: none;
        margin-bottom: $space * 3;
        padding-bottom: $space * 9;
    }

    &--pdp &__item img {
        min-width: 40px;
        max-width: 40px;
    }

    &--pdp &__item > * {
        width: 100%;
    }

    @include media(lg) {
        &__item {
            min-width: 50%;
        }

        &--pdp {
            display: block;
        }

        &--pdp &__item {
            display: block;
            max-width: 100%;
            min-width: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:579";