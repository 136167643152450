.password-validator {
    pointer-events: none;
}

.password__checklist {
    max-width: 300px;

    .fa-check {
        padding-top: 3px;
        display: none;
    }
    .fa-times {
        padding-top: 3px;
        color: $grey;
        display: inline-block;
    }
    .valid {
        .fa-check {
            display: inline-block;
        }
        .fa-times {
            display: none;
        }
    }
    .invalid {
        .fa-check {
            display: none;
        }
        .fa-times {
            color: $red;
            display: inline;
        }
    }
}

.strength {
    padding: $space * 3;

    &__bar--wrapper {
        position: relative;
        height: $space;
        width: 100%;
        margin-top: $space;
        background: $grey;
    }

    &--bar {
        content: '';
        position: absolute;
        background: $green;
        width: 0;
        top: 0;
        bottom: 0;
        left: 0;
    }

    &__label {
        display: none;
    }

    &--none {
        .strength__label--none {
            display: inline;
        }
    }

    &.strength--quarter {
        .strength__label--weak {
            color: $red;
            display: inline;
        }
        .strength--bar {
            background: $red;
        }
    }

    &.strength--three-quarter {
        .password__label--medium {
            color: $yellow;
            display: inline;
        }
        .strength--bar {
            background: $yellow;
        }
    }

    &.strength--full {
        .password__label--strong {
            color: $green;
            display: inline;
        }
        .strength--bar {
            background: $green;
        }
    }
}

;@import "sass-embedded-legacy-load-done:393";