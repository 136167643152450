.page-size-selector {
    align-items: center;
    display: flex;
    width: 100%;

    label {
        color: $grey--dark;
        display: none;
        font-weight: $font-weight--bold;
        margin-right: $space * 3;
        white-space: nowrap;
    }

    select {
        font-weight: bold;
    }

    @include media(xl) {
        width: auto;

        label {
            display: block;
        }

        select {
            max-width: 80px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:384";