//TODO: Deprecated - Remove once all usages are gone
//Refactored Version: /v2/components/_product-specs.organism.scss
//Refactored Version: /v2/components/_product-specs-lg.organism.scss

.product-specs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0 ($space * 2);

    img {
        max-width: 100%;
        float: right;
    }
}

@include media(lg) {
    .product-specs {
        padding: 0 $space;
        margin-left: inherit;
        margin-right: inherit;

        img {
            max-width: 90%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:510";